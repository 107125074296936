import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

function matchName(query, name) {
    //loop trough all artists
    for (let i = 0; i < query.length; i++) {
        let item = query[i]

        //check for hit if name is the same
        if (item.frontmatter.title === name) {
            return item.fields.slug
        }
    }
}

export default ({ name }) => {
    const data = useStaticQuery(graphql`
        query AristsQuery {
            allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "artist-page" } } }) {
                nodes {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                    }
                }
            }
        }
    `)
    return (
        <div>
            <Link to={matchName(data.allMarkdownRemark.nodes, name)}>{name}</Link>
        </div>
    )
}

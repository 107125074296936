import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import fallbackImage from "../img/art-item_noimage.png"
import SeeAllRow from "../components/SeeAllRow"
import BackButton from "../components/BackButton"
import { validURL, validRKD } from "../utils/helpers.js"
import ArtRollRelated from "../components/ArtRollRelated"
import MatchRelationPortrayed from "../components/MatchRelationPortrayed"
import MatchRelationArtist from "../components/MatchRelationArtist"
import { JsonLd } from "../components/JsonLd"

import "./styles/art-item.scss"

export const ArtItemPostTemplate = ({ description, title, artist, metaData, portrayedPersons, featuredImage }) => {
    let artistLink = artist.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    let RKDLink = String(metaData.rkdLink)

    return (
        <div className="page--wrapper art-item">
            <section className="art-item--page">
                <div className="sub-navigation">
                    <BackButton />
                </div>
                <h1 className="page--heading">{title}</h1>
                <div className="item--info">
                    <div className="info--person">
                        <div className="person--description" dangerouslySetInnerHTML={{ __html: description }}></div>
                    </div>
                    <div className="info--metadata">
                        <h4>Gegevens</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Kunstenaar</td>
                                    <td>{artist ? <MatchRelationArtist name={artist} /> : "Onbekend"}</td>
                                </tr>
                                {portrayedPersons ? (
                                    <tr>
                                        <td>Geportretteerde</td>
                                        <td>
                                            {portrayedPersons ? (
                                                <MatchRelationPortrayed name={portrayedPersons} />
                                            ) : (
                                                "Onbekend"
                                            )}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td>Objectnummer</td>
                                    <td>{metaData.objectNumber || "Onbekend"}</td>
                                </tr>
                                <tr>
                                    <td>Techniek</td>
                                    <td>{metaData.usedTechnique || "Onbekend"}</td>
                                </tr>
                                <tr>
                                    <td>Afmetingen</td>
                                    <td>{metaData.objectMeasurements || "Onbekend"}</td>
                                </tr>
                                <tr>
                                    <td>IB Nummer</td>
                                    <td>{metaData.objectIbNumber || "Onbekend"}</td>
                                </tr>
                                <tr>
                                    <td>Datering</td>
                                    <td>{metaData.objectTimeperiod || "Onbekend"}</td>
                                </tr>
                                <tr>
                                    <td>RKD</td>
                                    <td>
                                        {validURL(RKDLink) || validRKD(RKDLink) ? (
                                            <a href={metaData.rkdLink} target="_blank" rel="noopener noreferrer">
                                                Bekijk
                                            </a>
                                        ) : (
                                            metaData.rkdLink || "Onbekend"
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="item--image">
                    <figure className="art-item--preview">
                        {featuredImage ? (
                            <div className="featured-thumbnail">
                                <PreviewCompatibleImage
                                    imageInfo={{
                                        image: featuredImage,
                                        alt: `Volledige afbeelding voor het kunstwerk genaamd ${title}`,
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="fallback-thumbnail" style={{ backgroundImage: `url(${fallbackImage})` }}>
                                <p>Afbeelding is niet beschikbaar. Neem contact op met de beheerder.</p>
                            </div>
                        )}
                    </figure>
                </div>
            </section>
            <section className="section--related">
                <div className="container--related">
                    <h2>Gerelateerde kunstwerken</h2>
                    <div className="container--related">
                        <ArtRollRelated name={artistLink} />
                    </div>
                    <SeeAllRow to="/search" />
                </div>
            </section>
        </div>
    )
}

ArtItemPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
}

const ArtItemPost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <ArtItemPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.html}
                metaData={post.frontmatter.itemMetadata}
                featuredImage={post.frontmatter.featuredImage}
                artist={post.frontmatter.artist}
                portrayedPersons={post.frontmatter.portrayedPersons}
                title={post.frontmatter.title}
                helmet={
                    <Helmet titleTemplate="%s | Kunstobject">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta name="description" content={`${post.html}`} />
                        <JsonLd>
                            {{
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                name: post.frontmatter.title,
                                description: post.frontmatter.html,
                                image: post.frontmatter.featuredImage,
                                thumbnailUrl: post.frontmatter.featuredImage,
                                isFamilyFriendly: true,
                                accessMode: "textOnVisual",
                                sourceOrganization: "Stichting Cultuurhistorisch Fonds Boreel",
                                creator: {
                                    "@type": "Person",
                                    name: post.frontmatter.artist,
                                },
                                character: {
                                    "@type": "Person",
                                    name: post.frontmatter.portrayedPersons,
                                },
                                maintainer: {
                                    "@type": "Organization",
                                    name: "Stichting Cultuurhistorisch Fonds Boreel",
                                },
                            }}
                        </JsonLd>
                    </Helmet>
                }
            />
        </Layout>
    )
}

ArtItemPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default ArtItemPost

export const pageQuery = graphql`
    query ArtItemPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                artist
                portrayedPersons
                itemMetadata {
                    objectIbNumber
                    objectMeasurements
                    objectNumber
                    objectTimeperiod
                    rkdLink
                    usedTechnique
                }
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 75, toFormat: JPG) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
